@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap";
.glider, .glider-contain {
  margin: 0 auto;
  position: relative;
}

.glider, .glider-track {
  transform: translateZ(0);
}

.glider-dot, .glider-next, .glider-prev {
  user-select: none;
  border: 0;
  outline: 0;
  padding: 0;
}

.glider-contain {
  width: 100%;
}

.glider {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-y: hidden;
}

.glider-track {
  z-index: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.glider.draggable {
  user-select: none;
  cursor: -webkit-grab;
  cursor: grab;
}

.glider.draggable .glider-slide img {
  user-select: none;
  pointer-events: none;
}

.glider.drag {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.glider-slide {
  user-select: none;
  place-content: center;
  width: 100%;
  min-width: 150px;
}

.glider-slide img {
  max-width: 100%;
}

.glider::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}

.glider-next, .glider-prev {
  z-index: 2;
  cursor: pointer;
  color: #666;
  opacity: 1;
  background: none;
  font-size: 40px;
  line-height: 1;
  text-decoration: none;
  transition: opacity .5s cubic-bezier(.17, .67, .83, .67), color .5s cubic-bezier(.17, .67, .83, .67);
  position: absolute;
  top: 30%;
  left: -23px;
}

.glider-next:focus, .glider-next:hover, .glider-prev:focus, .glider-prev:hover {
  color: #ccc;
}

.glider-next {
  left: auto;
  right: -23px;
}

.glider-next.disabled, .glider-prev.disabled {
  opacity: .25;
  color: #666;
  cursor: default;
}

.glider-hide {
  opacity: 0;
}

.glider-dots {
  user-select: none;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  display: flex;
}

.glider-dot {
  cursor: pointer;
  color: #ccc;
  background: #ccc;
  border-radius: 999px;
  width: 12px;
  height: 12px;
  margin: 7px;
  display: block;
}

.glider-dot:focus, .glider-dot:hover {
  background: #ddd;
}

.glider-dot.active {
  background: #a89cc8;
}

@media (width <= 36em) {
  .glider::-webkit-scrollbar {
    opacity: 1;
    -webkit-appearance: none;
    width: 7px;
    height: 3px;
  }

  .glider::-webkit-scrollbar-thumb {
    opacity: 1;
    background-color: #9c9c9c40;
    border-radius: 99px;
    box-shadow: 0 0 1px #ffffff40;
  }
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.visible {
  visibility: visible;
}

.relative {
  position: relative;
}

.m-0 {
  margin: 0;
}

.-my-\[60px\] {
  margin-top: -60px;
  margin-bottom: -60px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-\[20px\] {
  margin-bottom: 20px;
}

.mb-\[40px\] {
  margin-bottom: 40px;
}

.mb-\[60px\] {
  margin-bottom: 60px;
}

.ml-\[4\.1665\%\] {
  margin-left: 4.1665%;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[40px\] {
  margin-top: 40px;
}

.mt-\[60px\] {
  margin-top: 60px;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.h-8 {
  height: 2rem;
}

.h-\[54px\] {
  height: 54px;
}

.h-\[5px\] {
  height: 5px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[70vh\] {
  height: 70vh;
}

.min-h-\[70vh\] {
  min-height: 70vh;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-\[115px\] {
  width: 115px;
}

.w-\[120px\] {
  width: 120px;
}

.w-\[191px\] {
  width: 191px;
}

.w-\[212px\] {
  width: 212px;
}

.w-\[54px\] {
  width: 54px;
}

.w-full {
  width: 100%;
}

.max-w-\[110px\] {
  max-width: 110px;
}

.max-w-\[290px\] {
  max-width: 290px;
}

.max-w-full {
  max-width: 100%;
}

.resize {
  resize: both;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-center {
  place-content: center;
}

.content-center {
  align-content: center;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-\[30px\] {
  gap: 30px;
}

.gap-\[60px\] {
  gap: 60px;
}

.place-self-end {
  place-self: end;
}

.self-center {
  align-self: center;
}

.justify-self-end {
  justify-self: end;
}

.overflow-hidden {
  overflow: hidden;
}

.text-balance {
  text-wrap: balance;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-mb-color-blue {
  --tw-border-opacity: 1;
  border-color: rgb(2 88 165 / var(--tw-border-opacity, 1));
}

.border-white\/10 {
  border-color: #ffffff1a;
}

.bg-mb-color-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(2 88 165 / var(--tw-bg-opacity, 1));
}

.bg-mb-color-light-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(225 241 255 / var(--tw-bg-opacity, 1));
}

.bg-mb-color-red {
  --tw-bg-opacity: 1;
  background-color: rgb(219 21 50 / var(--tw-bg-opacity, 1));
}

.p-0 {
  padding: 0;
}

.p-4 {
  padding: 1rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}

.py-\[16px\] {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-\[40px\] {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-\[40px\] {
  padding-bottom: 40px;
}

.pl-\[12px\] {
  padding-left: 12px;
}

.pr-\[12px\] {
  padding-right: 12px;
}

.pt-\[40px\] {
  padding-top: 40px;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[32px\] {
  font-size: 32px;
}

.text-\[36px\] {
  font-size: 36px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-\[16px\] {
  font-weight: 16px;
}

.font-bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.leading-\[120\%\] {
  line-height: 120%;
}

.leading-\[140\%\] {
  line-height: 140%;
}

.leading-\[160\%\] {
  line-height: 160%;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

.text-mb-color-blue {
  --tw-text-opacity: 1;
  color: rgb(2 88 165 / var(--tw-text-opacity, 1));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

.do-not-break {
  white-space: nowrap;
}

.lato-thin {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 100;
}

.lato-light {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 300;
}

.lato-regular {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.lato-bold {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
}

.lato-black {
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 900;
}

.lato-thin-italic {
  font-family: Lato, sans-serif;
  font-style: italic;
  font-weight: 100;
}

.lato-light-italic {
  font-family: Lato, sans-serif;
  font-style: italic;
  font-weight: 300;
}

.lato-regular-italic {
  font-family: Lato, sans-serif;
  font-style: italic;
  font-weight: 400;
}

.lato-bold-italic {
  font-family: Lato, sans-serif;
  font-style: italic;
  font-weight: 700;
}

.lato-black-italic {
  font-family: Lato, sans-serif;
  font-style: italic;
  font-weight: 900;
}

html {
  scroll-behavior: smooth;
}

body {
  padding-bottom: 2rem;
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

body.modal-active {
  width: 100%;
  position: fixed;
}

#hero {
  background-image: url("mb-hero-bg.efbd3346.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

#hero h1 {
  text-shadow: 0 4px 30px #00000080;
}

.logo {
  text-indent: -9999px;
  background-image: url("mb-white-logo.ae18d6d6.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 331px;
  height: 65px;
}

.min-border {
  background-color: #939598;
  width: 1px;
  height: 12px;
  display: inline-block;
}

.video-section {
  z-index: 1000;
  background: linear-gradient(#0258a5 0% 95%, #fff 95%);
  position: relative;
}

.video-section h2 {
  text-shadow: 0 4px 30px #00000080;
}

.embed-container {
  max-width: 100%;
  height: auto;
  padding-bottom: 56.2%;
  position: relative;
  overflow: hidden;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.stories-container {
  background: linear-gradient(0deg, #e1f1ff 0% 60%, #fff 60%);
  padding-bottom: 60px;
}

.mb-faded-bg {
  background-image: url("mb-gray-watermark.ac3e85e6.svg");
  background-position: 100% 26px;
  background-repeat: no-repeat;
  background-size: 90%;
  padding-top: 60px;

  @media (width >= 768px) {
    & {
      background-position: 100% 16px;
      background-size: auto 80px;
      padding-top: 80px;
    }
  }
}

.stories-container header {
  justify-content: space-between;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
}

.slick-dots {
  position: initial;
  margin-top: 40px;
}

.slick-dots li button:before {
  opacity: 1;
}

.slick-dots li.slick-active button {
  position: relative;
}

.slick-dots li.slick-active button:before {
  content: "";
  background: none;
  border: 1px solid #000;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  position: absolute;
  top: calc(50% - 2.5px);
  left: calc(50% - 2.5px);
}

.slick-dotted.slick-slider {
  margin-bottom: 40px;
}

.prev, .next {
  width: 48px;
  height: 48px;
}

.prev svg, .next svg {
  width: 100%;
  height: 100%;
}

.story {
  margin: 0 10px;
}

.story .still {
  cursor: pointer;
  background: #d3d3d3 linear-gradient(0deg, #0003 0% 100%) center / cover no-repeat;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  display: flex;
  position: relative;
}

.story .still svg {
  stroke-width: .5px;
}

.story .still svg path {
  stroke: #fff;
}

.story .still svg path.triangle {
  fill: #fff;
}

.story .still:hover svg {
  stroke-width: 1px;
}

.story .still:hover svg path {
  stroke: #db1532;
}

.story .still:hover svg path.triangle {
  fill: #db1532;
}

.story h3 {
  color: #000;
  margin: 30px 0 12px;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.story p span {
  font-size: 1.1rem;
  display: block;
}

.story p span.job-title {
  display: none;
}

.timeline-container header {
  justify-content: space-between;
  max-width: 100%;
  margin: 100px auto 60px;
  display: flex;
}

.timeline-top-nav {
  min-width: ;
}

.entry {
  vertical-align: top;
  border: none;
  margin-bottom: 10px;
  margin-right: 15px;
  padding-right: 0;
  display: inline-block;
}

.entry img {
  max-height: 420px;
  display: block;
}

@media (width >= 768px) {
  .entry {
    border-right: 1px solid #d9d9d9;
    padding-right: 15px;
  }

  .entry img {
    height: 420px;
  }
}

.entry h3 {
  color: var(--Black, #000);
  padding: 12px 0;
  font-family: Lato;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.entry p {
  color: var(--Black, #000);
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}

#video-modal {
  z-index: 10000;
  background-color: #000000d9;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  inset: 0;
  overflow: auto;
}

#video-modal.open {
  display: block;
}

#video-modal .modal-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
}

#video-modal .embed-container {
  width: 100%;
}

#video-modal .close {
  cursor: pointer;
}

footer .bg-circle {
  background-image: url("circle-bg.7c003116.svg");
  background-repeat: no-repeat;
}

.hover\:border-mb-color-blue:hover {
  --tw-border-opacity: 1;
  border-color: rgb(2 88 165 / var(--tw-border-opacity, 1));
}

.hover\:bg-mb-color-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(2 88 165 / var(--tw-bg-opacity, 1));
}

.hover\:bg-mb-color-dark-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(141 13 31 / var(--tw-bg-opacity, 1));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

@media (width >= 640px) {
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:w-\[290px\] {
    width: 290px;
  }

  .sm\:text-left {
    text-align: left;
  }
}

@media (width >= 768px) {
  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:text-\[48px\] {
    font-size: 48px;
  }
}

@media (width >= 1024px) {
  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:mb-\[80px\] {
    margin-bottom: 80px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-\[100px\] {
    margin-top: 100px;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:min-h-\[80vh\] {
    min-height: 80vh;
  }

  .lg\:max-w-\[1200px\] {
    max-width: 1200px;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-\[42px\] {
    font-size: 42px;
  }

  .lg\:text-\[48px\] {
    font-size: 48px;
  }

  .lg\:text-\[54px\] {
    font-size: 54px;
  }

  .lg\:text-\[68px\] {
    font-size: 68px;
  }

  .lg\:leading-\[140\%\] {
    line-height: 140%;
  }
}

@media (width >= 1280px) {
  .xl\:px-\[80px\] {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media (width >= 1536px) {
  .\32 xl\:w-\[900px\] {
    width: 900px;
  }
}
/*# sourceMappingURL=index.adcf4955.css.map */
