@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import 'glider.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.do-not-break {
	white-space: nowrap;
}

.lato-thin {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: normal;
}

.lato-light {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: normal;
}

.lato-regular {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.lato-bold {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: normal;
}

.lato-black {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: normal;
}

.lato-thin-italic {
	font-family: "Lato", sans-serif;
	font-weight: 100;
	font-style: italic;
}

.lato-light-italic {
	font-family: "Lato", sans-serif;
	font-weight: 300;
	font-style: italic;
}

.lato-regular-italic {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: italic;
}

.lato-bold-italic {
	font-family: "Lato", sans-serif;
	font-weight: 700;
	font-style: italic;
}

.lato-black-italic {
	font-family: "Lato", sans-serif;
	font-weight: 900;
	font-style: italic;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: "Lato", sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 140%;
	padding-bottom: 2rem;
}

body.modal-active {
	position: fixed;
	width: 100%;
}

#hero {
	background-image: url('../images/mb-hero-bg.jpg');	
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
}

#hero h1 {
	text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.50);
}

.logo {
	background-image: url('../images/mb-white-logo.svg');
	background-repeat: no-repeat;
	background-size: contain;
	height: 65px;
	text-indent: -9999px;
	width: 331px;
}

.min-border {
	background-color: #939598;
	display: inline-block;
	height: 12px;
	width: 1px;
}

/**
 * Video section
 */
.video-section {
	background: linear-gradient(180deg, #0258A5 0%, #0258A5 95%, #FFFFFF 95%);
	position: relative;
	z-index: 1000;
}

.video-section h2 {
	text-shadow: 0px 4px 30px rgba(0, 0, 0, 0.50);
}

.embed-container {
	position: relative;
	padding-bottom: 56.2%;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/**
 * Stories
 */
.stories-container {
	background: linear-gradient(0deg, #E1F1FF 0%, #E1F1FF 60%, #FFFFFF 60%);
	padding-bottom: 60px;
}

.mb-faded-bg {
	background-image: url('../images/mb-gray-watermark.svg');
	background-position: 100% 26px;
	background-repeat: no-repeat;
	background-size: 90%;
	padding-top: 60px;

	@media (min-width: 768px) {
		background-position: 100% 16px;
		background-size: auto 80px;
		padding-top: 80px;
	}
}

.stories-container header {
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	max-width: 90%;
}

.slick-dots {
	margin-top: 40px;
	position: initial;
}

.slick-dots li button:before {
	opacity: 1;
}

.slick-dots li.slick-active button {
	position: relative;
}

.slick-dots li.slick-active button:before {
	background: transparent;
	border-radius: 50%;
	content: '';
	border: 1px solid #000;
	height: 5px;
	position: absolute;
	top: calc(50% - 2.5px);
	left: calc(50% - 2.5px);
	width: 5px;
}

.slick-dotted.slick-slider {
	margin-bottom: 40px;
}

.prev,
.next {
	height: 48px;
	width: 48px;
}

.prev svg,
.next svg {
	height: 100%;
	width: 100%;
}

.story {
	margin: 0 10px;
}

.story .still {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%) lightgray 0px -15.25px / 100% 166.667%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 200px;
	justify-content: center;
	position: relative;
}

.story .still svg {
	stroke-width: 0.5;
}

.story .still svg path {
	stroke: #ffffff;
}

.story .still svg path.triangle {
	fill: #ffffff;
}

.story .still:hover svg {
	stroke-width: 1;
}

.story .still:hover svg path {
	stroke: #DB1532;
}

.story .still:hover svg path.triangle {
	fill: #DB1532;
}

.story h3 {
	color: #000;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 28px */
	margin: 30px 0 12px;
}

.story p span {
	display: block;
	font-size: 1.1rem;
}

.story p span.job-title {
	display: none;
}

/**
 * Timeline
 */
.timeline-container header {
	display: flex;
	justify-content: space-between;
	margin: 100px auto 60px auto;
	max-width: 100%;
}

.timeline-top-nav {
	min-width:;
}

.entry {
	border: none;
	display: inline-block;
	margin-bottom: 10px;
	margin-right: 15px;
	padding-right: 0;
	vertical-align: top;
}

.entry img {
	display: block;
	max-height: 420px;
}

@media (min-width: 768px) {
	.entry {
		border-right: 1px solid #D9D9D9;
		padding-right: 15px;
	}

	.entry img {
		height: 420px;
	}
}

.entry h3 {
	color: var(--Black, #000);
	font-family: Lato;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 140%; /* 39.2px */
	padding: 12px 0;
}

.entry p {
	color: var(--Black, #000);
	font-family: Lato;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 160%; /* 28.8px */
}

/**
 * Modal styles
 */
#video-modal {
	background-color: rgba(0,0,0, 0.85);
	display: none;
	height: 100vh;
	inset: 0;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 10000;
}

#video-modal.open {
	display: block;
}

#video-modal .modal-container {
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	margin: 0 auto;
	max-width: 900px;
}

#video-modal .embed-container {
	width: 100%;
}

#video-modal .close {
	cursor: pointer;
}

/**
 * Footer styles
 */
footer .bg-circle {
	background-image: url('../images/circle-bg.svg');
	background-repeat: no-repeat;
}